import { useSegmentReset } from '~/composables/Segment/useSegmentReset'
import { useCleanCache } from '~/composables/useCleanCache'

import { useMiscDraftStore } from '~/stores/miscDraft'
import { useUserStore } from '~/stores/user'

import { resetGrowthBookIdentityAttributes } from '~/helpers/GrowthBookHelpers'

export function useLogout() {
  const localePath = useLocalePath()
  const { $growthBook } = useNuxtApp()
  const { cleanCacheAll } = useCleanCache()
  const segmentReset = useSegmentReset()

  const userStore = useUserStore()
  const { IS_BAND } = storeToRefs(userStore)
  const { LOGOUT } = userStore

  const { SET_LOGIN_OUT } = useMiscDraftStore()

  async function logout(
    { shouldRedirect }: { shouldRedirect: boolean } = { shouldRedirect: true },
  ) {
    if (IS_BAND.value) SET_LOGIN_OUT()

    await LOGOUT()
    cleanCacheAll()
    segmentReset()
    resetGrowthBookIdentityAttributes($growthBook)
    if (shouldRedirect)
      reloadNuxtApp({ path: localePath('/'), ttl: 1000, force: true })
  }

  return { logout }
}
